export const ROUTES = Object.freeze({
  HOME: '/',
  ABOUT: '/about',
  PRICING: '/pricing',
  TEXT_TO_PAY: '/text-to-pay',
  ONLINE_INVOICING: '/online-invoicing',
  ONLINE_CHECKBOOK: '/online-checkbook',
  ONLINE_PAYMENTS: '/online-payments',
  FREE_INVOICE_GENERATOR: '/invoice-generator',
  ONLINE_CHECKOUT_OPTIONS: '/online-checkout-options'
} as const)
