import React, { ReactNode } from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import SendIcon from '@mui/icons-material/Send'
import Box from '@mui/material/Box'

export interface PtmLoadingButtonProps extends LoadingButtonProps {
  bgColor?: string
  textColor?: string
  children?: ReactNode
}

const PtmLoadingButton: React.FC<PtmLoadingButtonProps> = ({
  bgColor = '#0000F6',
  textColor = '#ffffff',
  children,
  ...rest
}) => {
  const variant = rest?.variant || ''
  const loading = rest?.loading || false
  const disabled = rest?.disabled || false

  // console.log({ textColor })

  return (
    <Box
      sx={{
        ['.MuiLoadingButton-loadingIndicatorStart']: {
          marginRight: '8px',
          position: 'unset'
        },
        ['.MuiLoadingButton-loadingIndicatorEnd']: {
          marginLeft: '8px',
          position: 'unset'
        },
        ['.custom-loading-button']: {
          background:
            disabled || (loading && variant !== 'text') ? 'rgba(50, 71, 92, 0.12) !important' : `${bgColor} !important`,
          color:
            disabled || (loading && variant !== 'text')
              ? 'rgba(50, 71, 92, 0.85) !important'
              : `${textColor} !important`,
          transition: 'ease-in-out 300ms'
        }
      }}
    >
      <LoadingButton
        className={'custom-loading-button font-semibold'}
        variant='contained'
        size={'small'}
        loadingPosition={'start'}
        startIcon={
          <span style={{ display: 'none' }}>
            <SendIcon />
          </span>
        }
        endIcon={
          <span style={{ display: 'none' }}>
            <SendIcon />
          </span>
        }
        {...rest}
      >
        {children}
      </LoadingButton>
    </Box>
  )
}

export default PtmLoadingButton
