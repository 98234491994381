'use client'

/* export const setCookie = (cName, cValue, expiryDay) => {
  const today = new Date()
  today.setTime(today.getTime() + expiryDay * 24 * 60 * 60 * 1000)

  const expires = 'expires=' + today.toUTCString()
  document.cookie = cName + '=' + cValue + ';' + expires + ';path=/'
} */

const environment = process.env.NEXT_PUBLIC_NODE_ENV // local, development, production
const IS_DEVELOPMENT = ['development', 'local'].includes(environment)
const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN
const COOKIE_EXPIRE_IN_DAYS = process.env.NEXT_PUBLIC_COOKIE_EXPIRE_IN_DAYS
const COOKIE_SECURE = !IS_DEVELOPMENT

export const setCookie = (cName, cValue, days = 10) => {
  const today = new Date()
  const expireDays = parseInt(COOKIE_EXPIRE_IN_DAYS) || Number(days) || 10
  today.setTime(today.getTime() + expireDays * 24 * 60 * 60 * 1000) // Convert days

  const whenWillExpire = 'expires=' + today.toUTCString()
  const secure = COOKIE_SECURE
  const Domain = COOKIE_DOMAIN
  const sameSite = 'lax'

  document.cookie = `${cName}=${cValue};${whenWillExpire};Secure=${secure};SameSite:${sameSite};domain=${Domain};path=/;priority=high`
}

export const getCookie = cName => {
  const name = cName + '='
  const decodedCookie = typeof document !== 'undefined' ? decodeURIComponent(document.cookie) : ''
  const numberOfCookies = decodedCookie.split(';')

  for (let i = 0; i < numberOfCookies.length; i++) {
    let eachCookie = numberOfCookies[i]
    while (eachCookie.charAt(0) === ' ') {
      eachCookie = eachCookie.substring(1)
    }
    if (eachCookie.indexOf(name) === 0) {
      return eachCookie.substring(name.length, eachCookie.length)
    }
  }

  return ''
}

export const removeCookies = cookies => {
  cookies.forEach(name => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/`
  })
}
